@import '../index.scss';

.register{
    height: 100vh;
    padding: calc($baseUnit/2);

    @media only screen and (min-width: $maxLaptop) {
       max-width: 600px;
       padding: 100px calc($baseUnit);
       margin: auto;
    }

    p{
        font-size: 12px;
        margin: calc($baseUnit/2) 0;
        @media only screen and (min-width: $maxLaptop) {
            font-size: 14px;
        }
    }
    
    h2{
        font-weight: 600;
        font-size: 18px;
        @media only screen and (min-width: $maxLaptop) {
            font-size: 20px;
        }
    }

    a{
        font-size: 12px;
        @media only screen and (min-width: $maxLaptop) {
            font-size: 14px;
        }
    }

    form{
        margin-top: calc($baseUnit);
        display: flex;
        flex-direction: column;
        gap: calc($baseUnit/2);


        label{
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 5px;

            span{
                font-size: 12px;
            }
            input{
                border-radius: 3px;
                outline: 1px solid rgb(229, 229, 229);
                border: none;
                padding: calc($baseUnit/2);   
                transition: all 400ms linear;

                &:focus{
                    outline: 1px solid $mainColor;
                }
            }
        }

        button{
            margin-top: 12px;
        }

        
    }
    .formError_p{
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 12px;
        color: $mainColor;
    }
}
@import '../index.scss';

.eventDetail{
    height: calc(100vh);

    @media only screen and (min-width: $maxLaptop) {
        display: flex;
        flex-direction: row;
    }
    
    &__banner{
        padding: 15px 15px 0 15px;
        height: 20vh;
        background-image: url('https://images.unsplash.com/photo-1707501734938-57bac2fb7f81?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media only screen and (min-width: $maxLaptop) {
            height: 100vh;
            width: 40%;
        }
    }

    &__info {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: calc($baseUnit/2);
        grid-row-gap: calc($baseUnit/2);
        padding: $baseUnit 0;
        border-bottom: 1px solid rgb(211, 211, 211);

        .item1 { grid-area: 1 / 1 / 3 / 3; }
        .item2 { grid-area: 1 / 3 / 3 / 5; }
        .item3 { grid-area: 3 / 1 / 5 / 3; }
        .item4 { grid-area: 3 / 3 / 5 / 5; }

        &__item{
            div{
                background-color: $mainColorLight;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                border-radius: 30px;
                gap: 10px;

                span{
                    font-size: 12px;
                    color: $mainColor;

                    @media only screen and (min-width: $maxLaptop) {
                        font-size: 14px;
                    }
                }

                img{
                    height: 20px;
                    @media only screen and (min-width: $maxLaptop) {
                        height: 24px;
                    }
                }
            }
            
        }
    }
        
        

    &__content{
        position: relative;
        height: 80vh;
        padding: 15px 15px 0 15px;

        @media only screen and (min-width: $maxLaptop) {
            height: 100vh;
            width: 60%;
        }

        &__title{
            padding-bottom: calc($baseUnit/2);
            border-bottom: 1px solid rgb(211, 211, 211);
            h2{
                font-weight: 600;
                color: $mainColor;
            }
            h3{
                font-weight: 500;
                font-size: 16px;
            }
        }

        p{
            margin-top: 12px;
            font-size: 12px;

            @media only screen and (min-width: $maxLaptop) {
                font-size: 14px;
            }

            span{
                font-weight: 500;
            }
        }

        .alert{
            position: absolute;
            bottom: 72px;
            width: calc(100% - 30px);
            color: $mainColor;
        }

        &__action{
            display: flex;
            flex-direction: column;
            gap: 12px;
            position: absolute;
            bottom: 15px;
            width: calc(100% - 30px);
        }
    }
}
//FONT
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');

//VARIABLE
$mainColor : #FF3A3A;
$mainColorLight : #ffdada;
$mainColorHover : #fb2626;
$danger : #FF3A3A;
$dangerLight : #ffdfdf;
$mainBg :#E9EFF2;
$secondBg : #F9F9F9;
$thirdBg: #F6F6F6;
$borderColor: #E9E9E9;
$fontColor: #2c2c2c;
$titleColor: #161616;
$hr : rgba(110, 113, 122, 0.38);
$focus : rgb(196, 196, 196);

$navHeight:90px;
$baseUnit: 24px;

$maxMobile : 480px;
$maxTablet : 768px;
$maxLaptop : 1024px;
$maxLaptopL : 1440px;
$maxDesktop : 2560px;


//GENERAL
*{
  -webkit-tap-highlight-color: $mainColorLight;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

body{
  width: 100%;
  min-height: 100vh;
  background-color: $mainBg;
  color: $fontColor;
}

p,h1,h2,h3,h3{
  margin:0;
  font-weight: 400;
}

a{
  text-decoration: none;
  color: inherit;

  &:focus{
    outline: none;
  }
}

ul{
  padding: 0;
  margin: 0;
  list-style-type: none;

  li{
    margin: 0;
    padding: 0;
  }
}

.eventBloc{
  display: flex;
  gap:calc($baseUnit/2);
  align-items: center;
  justify-content: space-between;
  padding: calc($baseUnit/2);
  border-radius: 10px;
  height: 120px;
  background-color: $secondBg;
  box-shadow: 0px 9px 15px -2px rgba(0,0,0,0.08);
  
  &__img{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      aspect-ratio: 1/1;

      img{
          border-radius: 20px;
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
  }

  &__main{
      display: flex;
      flex-direction: column;
      font-size: 14px;
      width: 100%;
      height: 100%;
      &__title{
          font-weight: 600;
          font-size: 16px;
          color: $titleColor;
      }
  }

  &__end{
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      justify-content: space-between;
      p{
          display: flex;
          flex-direction: column;
          text-align: end;
          color: $mainColor;
          font-size: 12px;

          .count{
              font-size: 18px;
              font-weight: 500;
          }
      }

      img{
          height:25px;
      }
  }

}

.mainButton, .scndButton, .thirdButton{
  background-color: $mainColor;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  padding: calc($baseUnit/2); 
  border-radius: 5px;  
  width: 100%;
  transition: all 100ms linear;
  cursor: pointer;

  &:hover, &:focus{
      background-color: #d91f1f;
      color: white;
  }

  &[disabled] {
    opacity: .4;
  }
}

.scndButton{
  background-color: transparent;
  color: #222222;

  &:hover, &:focus{
    background-color: #ffe6e6;
    color: #222222;
  }
}

.thirdButton{
  background-color: $dangerLight;
  color: $danger;
}

.modal{
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.472);
  display: flex;
  align-items: center;
  justify-content: center;

  div{
    background-color: $mainBg;
    width: calc(100% - 30px);
    max-width: 600px;
    display: flex;
    flex-direction: column;
    padding: 48px 12px 24px 12px;
    border-radius: 10px;
    gap: 10px;

    p{
      background-color: transparent;
      text-align: center;
      font-size: 14px;
    }

    h3{
      color: $mainColor;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
    }
  }
}

.loader {
  width: $baseUnit;
  aspect-ratio: 1/1;
  margin: $baseUnit auto;
  border: 1px solid $fontColor;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

.goBackLink{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: white;
  gap: 6px;
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 30px;
  width: fit-content;
  padding: 3px 12px;
  backdrop-filter: blur(30px);

  img{
      width: 24px;
  }
}

.noContent{
  text-align: center;
  font-size: 14px;
  padding: 30px 0;

}

.Toastify__toast--rtl {
  font-size: 12px!important;
}

@import '../index.scss';

.navbar{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $navHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $baseUnit;
    background-color: $mainBg;

    &__link{
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        width: 25%;
        padding: 3px 0;
        transition: all 200ms linear;

        span{
            font-size: 12px;
        }
    }

    .active-link{
        background-color: $mainColorLight;
        span{
            color: $mainColor;
        }
    }
}
@import '../index.scss';

.home{
    height: calc(100vh - $navHeight);
    padding: 30px 15px 0 15px;

    &__head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $baseUnit;
        margin-bottom: calc($baseUnit*1);

        input{
            padding: 0 12px;
            height: 50px;
            font-size: 14px;
            border: 1px solid $borderColor;
            outline: solid 0px $mainColor;
            width: 100%;
            border-radius: 5px;
            transition: all 200ms linear;
            background-color: $secondBg;

            &:focus{
                outline: solid 1px $mainColor;
            }
        }
    }

    &__filters{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: calc($baseUnit*2);
        margin-bottom: calc($baseUnit*1);

        .filterActive{
            color: $mainColor;
            position: relative;

            &::before{
                content: '';
                position: absolute;
                transform: translate(-50%, 0%);
                bottom: -3px;
                left: 50%;
                width: 4px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background-color: $mainColor;
            }
        }
    }

    &__events{
        height: calc(100% - 122px);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: $baseUnit;

        li{
            width: 100%;

            a{
                width: 100%;
            }
        }

        @media only screen and (min-width: $maxLaptop) {
            height: fit-content;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px; 
            li{
                height: fit-content;
            }
        }
    }

    
}